import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import styles from './Rutdao.module.scss'
import { RutdaoSubmenuRoute } from '../../share/route-option';
import { retrieveObjectFromLocalStorage } from '../../share/sharedFunction';
import { arrEmail1, arrEmail2 } from "../../share/arrRole";

import { useSelector, useDispatch } from 'react-redux'; //useSelector: hook lấy state từ todoSlice.js ; useDispatch: hook phát đi đến todoSlice.js
import { update_view } from './../../../features/generalSlice.js';

function RutdaoSubMenu() {

    const [stateObjSignIn, setStateObjSignIn] = useState<Record<string, any>>({
        email: "",
        fullName: "",
        role: "",
        token:""
    })
    const checkSignin = () => {
    
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        // console.log('userExist: ', userExist);
        if(userExist === null){
            return
        }
        setStateObjSignIn(userExist);
    
    }

    useEffect(() => {
        checkSignin();
    },[]);
    const [stateSidebarItems, setStateSidebarItems] = useState(RutdaoSubmenuRoute); //tạo state toggle và cho nội dung của nó ban đầu là mảng AdminMenuRoute
    const [defaultView, setDefaultView] = useState(true); //lý do có state này là ban đầu cho cái default view nó hiện ra, chứ nếu ko là ban đầu nó hiện ra trang trắng, khi user lick vào các item bên sidebar thì state này đc set về false để ẩn đi default view nhường chỗ lại cho các trang khác hiện ra
    
    const [open, setOpen] = useState(true);

    const dispatch = useDispatch(); //hook phát đi đến GeneralSlice.js

    const navigate = useNavigate(); //hook dùng để chuyển trang web

    const [stateString_submenuItem, setStateString_submenuItem] = useState<string>(''); // string
    const select_subMenuItem = (title: string) => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return;
        }
        const email = userExist.email;
        switch (title) {
            case 'Khách hàng':
                navigate("/finance/khachhang", { replace: true }); 
                // makeSelectedItemBlue("Khách hàng"); 
                
                setStateString_submenuItem(title);
                break;
            case 'Giao dịch':
                // alert("Tính năng này đang phát triển ! Bạn sẽ đường điều hướng lại về trang Thông Tin");
                navigate("/finance/giaodich", { replace: true }); 
                setStateString_submenuItem(title);
            break;
            case 'Thẻ tín dụng':
                navigate("/finance/thetindung", { replace: true }); 
                setStateString_submenuItem(title);
                break;
            case 'Quỹ':
                if(!arrEmail1.includes(email)){
                    alert("Bạn không có quyền xem tính năng này !");
                    return;
                }
                navigate("/finance/quy", { replace: true }); 
                setStateString_submenuItem(title);
            break;
            case 'Thu Chi':
                if(!arrEmail1.includes(email)){
                    alert("Bạn không có quyền xem tính năng này !");
                    return;
                }
                navigate("/finance/thuchi", { replace: true }); 
                setStateString_submenuItem(title);
            break;
            case 'Sao kê':
                if(!arrEmail1.includes(email)){
                    alert("Bạn không có quyền xem tính năng này !");
                    return;
                }
                navigate("/finance/saoke", { replace: true }); 
                setStateString_submenuItem(title);
            break;
  
            case 'Nhân viên':
                alert("Tính năng này đang phát triển, hiện tại chưa dùng được.");

                // alert("Tính năng này đang phát triển, hiện tại chưa dùng được.");

                // alert("Tính năng này đang phát triển ! Bạn sẽ đường điều hướng lại về trang Thông Tin");
                // navigate("/cskh/nhapthongtin", { replace: true }); 
                // makeSelectedItemBlue("Đáo"); 

                break;
            default:
                break;
        }
    }
    
    const selectView = (view: number) => {
        
        dispatch(update_view(view));//phát đi tới todoSlice.js, chui vào hàm handleStatus với tham số là idClickedObject
    
    }
    return (
        stateObjSignIn.token === "" ? <span></span> :

        <div className={clsx(styles.component_RutdaoSubMenu)}>
            <div className={clsx(styles.leftSubMenu)}>

            </div>
            <div className={clsx(styles.middleSubMenu)}>

                {
                    stateSidebarItems.map((obj, index) => {
                        let title: string = obj.title;
                        let status: Boolean = obj.status;
                        return (
                            <p key={obj.id} className={clsx(styles.subMenuItem, {
                                [styles.selectedSubMenuItem]: obj.title === stateString_submenuItem,
                                })} onClick={()=>select_subMenuItem(obj.title)}>
                                {/* <Link to={object.path} 
                                    onClick={() => tabClick(title)} 
                                    className={clsx({[styles.selected]: status}, styles.subMenuItem)}>
                                    {obj.title}
                                </Link> */}
                                {obj.title}
                            </p>
                        )
                    })
                }
            </div>
            <div className={clsx(styles.rightSubMenu)}>
                <div className={clsx(styles.viewWrapper)}>
                    {/* <span className={clsx(styles.iconWrapper)}>
                        <img src="../assets/view/3.svg" className="3" alt="3" onClick={()=>selectView(3)}/>
                    </span> */}
                    <span className={clsx(styles.iconWrapper, styles.middle)}>
                        <img src="../assets/view/2.svg" className="2" alt="2" onClick={()=>selectView(2)}/>
                    </span>
                    <span className={clsx(styles.iconWrapper)}>
                        <img src="../assets/view/1.svg" className="1" alt="1" onClick={()=>selectView(1)}/>
                    </span>
                </div>
            </div>
        </div>
    )
}
export default RutdaoSubMenu