// recft_clsx_module_scss
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import styles from '../general/general.module.scss'     //file scss cùng cấp
import { useDispatch } from 'react-redux'; //useSelector: hook lấy state từ jobSlice.js ; useDispatch: hook phát đi đến jobSlice.js

import collectionAPI from '../../../API/collectionAPI';
import { storeObjectInLocalStorage } from '../../share/sharedFunction';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { isSignIn, setState_objSignin } from '../../../features/generalSlice';
function SignIn() {
   
   const [stateFormLogin, setstateFormLogin] = useState({email: "", password: ""});
   const navigate = useNavigate(); //hook dùng để chuyển trang web
   const dispatch = useDispatch(); 

   const clearOldStorage = () => {
   
      sessionStorage.clear(); // remove tất cả session cũ
      localStorage.clear(); // remove tất cả localstorage cũ
   
   }
   useEffect(() => {
      clearOldStorage();
   },[]);


   const handleSubmitSignIn = async (e:any) => {
      e.preventDefault();
      // console.log('stateFormLogin: ', stateFormLogin);
      try {
         let response = await collectionAPI.collectionAPI.login1(stateFormLogin);
         // console.log('response: ', response);

         if (response.data !== undefined) { // nếu trong server mà có data thì đi nhánh này, nó có data trong response

            if(response.data.status === "success login & token saved"){
               const employeeInfo = response.data.employee;
               let obj = {
                  token: response.data.access_token,
                  email: response.data.employee.email,
                  fullName: response.data.employee.fullName,
                  role: response.data.employee.role,
                  id: response.data.employee.id
               }
               dispatch(isSignIn(true)); // explain 1
               dispatch(setState_objSignin(obj)); // explain 1

               sessionStorage.clear(); // remove tất cả session cũ
               localStorage.clear(); // remove tất cả localstorage cũ
               
               storeObjectInLocalStorage("loginSuccessEmployee", obj, 8);
               navigate("/home", { replace: true }); //chuyển đến trang successLogin <Route path="/successLogin" element={<SuccessLogin/> } />
            }
            else if(response.data.status === "This employee is already logged in."){
               alert("Bạn đã dùng tài khoản này để đăng nhập ở 1 phiên làm việc khác. Vui lòng sign out trước khi sign in lại");
            }
            else if (response.data.status === "fail"){ 
               // console.log('response.data.status: ', response.data.status);
               alert(response.data.message);
            }
         } 
   
      } catch (err) {
         // console.log('err:', err);
      }
   }

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      
      setstateFormLogin({
         ...stateFormLogin,
         [event.target.name]: event.target.value,
      })
   
   }
   const reset = () => {
      setstateFormLogin({email: "", password: ""})
   }

   const [stateBool_showPass, setStateBool_showPass] = useState(false); // boolean
   const handleTogglePassword = () => {
   
      setStateBool_showPass(!stateBool_showPass);
   
   }

   // ramdom image start
   const [randomNumber, setRandomNumber] = useState(0);

   // Function to generate a random number between 1 and 4
   const getRandomNumber = () => {
     return Math.floor(Math.random() * 4) + 1;
   };
   
   // Function to update the random number state
   const updateRandomNumber = () => {
      const newRandomNumber = getRandomNumber();
      setRandomNumber(newRandomNumber);
   };

   // Call updateRandomNumber when the component mounts to show a random image initially
   useEffect(() => {
      updateRandomNumber();
   }, []);
   // ramdom image end
   return (
      <div className={clsx(styles.component_SignIn)}>
         <div className={clsx(styles.container)}>
            <div className={clsx(styles.left)}>
               <img src={`./assets/signin/${randomNumber}.png`} className="avatar" alt="avatar" />
            </div>
            <div className={clsx(styles.formLogin)}>
               <form onSubmit = {handleSubmitSignIn}>
                  {/* <HomeIcon className={clsx(styles.home)} onClick={goToHome}/> */}
                  {/* <h4>
                     <span>
                        Welcome to our services
                     </span> 
                     <span>
                        <img src="./assets/logo/logo_icon.png" className="avatar" alt="avatar" />
                     </span>
                  </h4> */}
                  <h2>SIGN IN</h2>
                  <div className={clsx(styles.row)}>
                     <label>Email: </label>
                     <span className={clsx(styles.box)}>
                        <input type="text" name="email" value={stateFormLogin.email} onChange={handleChange} placeholder='email...'/><div></div>
                     </span>
                  </div>
                  <div className={clsx(styles.row)}>
                     <label>Password: </label>
                     <span className={clsx(styles.box)}>
                        <input type={stateBool_showPass ? 'text' : 'password'} name="password" value={stateFormLogin.password} onChange={handleChange} placeholder='password...'/> 
                        <span className={clsx(styles.eyeWrapper)}><RemoveRedEyeIcon className={clsx(styles.eye)} onClick={handleTogglePassword}/></span>
                     </span>
                  </div>

                  <div className={clsx(styles.btnWrapper)}>
                        
                     <button type="submit" >Submit</button>
                     <button type="reset" onClick={reset}>Reset</button>
                  
                  </div>
                  {/* <p className={clsx(styles.already)}>
                     Does not have an account ? Click here to 
                     <span><Link className={clsx(styles.link)} to="/signup"> sign Up</Link></span>
                  </p> */}
               </form>
               {/* <button onClick={testFunctionRedux}>testFunctionRedux</button> */}
               {/* {
                  stateSignInFail &&
                  <p style={{ color: 'red' }}>Fail sign in !. Email or password is incorrect, or your account has been blocked. Please try again</p>
               } */}
            </div>
         </div>
      </div>
   )
}
export default SignIn