export const ROUTE_NAME = {
   HOME: "/home",
   HR: "/hr",
   CSKH: "/cskh",
   FINANCE: "/finance",
   KETOAN: "/ketoan",
   BGD: "/bgd",
   // PHANANH: "/phananh",
   QUANLY: "/quanly",
   AGENT: "/agent",
   INSTRUCTION: "/instruction",
   SIGNIN: "/signin",
   SIGNOUT: "/signout",
   SIGNUP: "/signup",
   CANDIDATEACCOUNT: "/candidateaccount",
   CONTROLLER:"/controller",
   EMPLOYER:"/employer",

}
export const MenuRoute = [
   {
      id: 0,
      path: ROUTE_NAME.HOME,
      title: "HOME",
      status: false
      // iconName: "channel"
   }
   ,
   {
      id: 1,
      path: ROUTE_NAME.HR,
      title: "HR",
      status: false
      // iconName: "channel"
   }
   ,
   {
      id: 2,
      path: ROUTE_NAME.CSKH,
      title: "CSKH",
      status: false
      // iconName: "dashboard"
   }
   ,
   {
      id: 3,
      path: ROUTE_NAME.FINANCE,
      title: "FINANCE",
      status: false
      // iconName: "dashboard"
   }
   ,
   {
      id: 4,
      path: ROUTE_NAME.KETOAN,
      title: "KẾ TOÁN",
      status: false
      // iconName: "dashboard"
   }
   ,
   {
      id: 5,
      path: ROUTE_NAME.BGD,
      title: "BGĐ",
      status: false
      // iconName: "dashboard"
   }
   // ,
   // {
   //    id: 5,
   //    path: ROUTE_NAME.PHANANH,
   //    title: "PHẢN ÁNH",
   //    status: false
   //    // iconName: "dashboard"
   // }
   ,
   {
      id: 6,
      path: ROUTE_NAME.QUANLY,
      title: "QUẢN LÝ",
      status: false
      // iconName: "dashboard"
   }
   ,
   {
      id: 7,
      path: ROUTE_NAME.AGENT,
      title: "AGENT",
      status: false
      // iconName: "dashboard"
   }
   ,

]

export const SubMenuAccount = [
   {
      id: 11,
      path: ROUTE_NAME.SIGNIN,
      title: "Sign in",
      iconName: "signin",
      type: "linkTopage"
   },
   {
      id: 12,
      path: ROUTE_NAME.SIGNOUT,
      title: "Sign out",
      iconName: "signout",
      type: "actionSignOut"
   },
   {
      id: 13,
      path: ROUTE_NAME.SIGNUP,
      title: "Sign up",
      iconName: "signup",
      type: "linkTopage"
},
   {
      id: 14,
      path: ROUTE_NAME.CANDIDATEACCOUNT,
      title: "Account",
      iconName: "account",
      type: "linkTopage"
   },

]

 //tập hợp các đường dẫn trong component EmployerHome.tsx
export const ROUTE_NAME_HR = {
   // HOME: "", 
   // lưu ý đường dẫn đến trang home của employer phải để rỗng, vì bên trong còn có các Link dẫn đến các component khác, 
   // nên nếu ở đây mình để là "home" thì các link đến component kia nó sẽ có chữ /home/ ở trước đường dẫn, dẫn đến sai, 
   // vì các Link nằm bên trong component EmployerIntro nên các Link đó theo nguyên tắc là con của coponent EmployerIntro
   UPLOAD: "upload",
   BAOCAO: "baocao",
   GUIMAIL: "guimail"
}

export const HRSidebarRoute = [
   {
      id: 1,
      path: ROUTE_NAME_HR.UPLOAD,
      title: "Upload",
      status: false
   },
   {
      id: 2,
      path: ROUTE_NAME_HR.BAOCAO,
      title: "Báo cáo",
      status: false
   },
   {
      id: 3,
      path: ROUTE_NAME_HR.GUIMAIL,
      title: "Gửi mail",
      status: false
   }
]
export const ROUTE_NAME_CSKH = {
   // HOME: "", 
   // lưu ý đường dẫn đến trang home của employer phải để rỗng, vì bên trong còn có các Link dẫn đến các component khác, 
   // nên nếu ở đây mình để là "home" thì các link đến component kia nó sẽ có chữ /home/ ở trước đường dẫn, dẫn đến sai, 
   // vì các Link nằm bên trong component EmployerIntro nên các Link đó theo nguyên tắc là con của coponent EmployerIntro
   NHAPTHONGTIN: "nhapthongtin",
   BAOCAO: "nhapthongtin",
   GUIMAIL: "nhapthongtin",
   UPLOAD: "nhapthongtin"

   // BAOCAO: "baocao",
   // GUIMAIL: "guimail",
   // UPLOAD: "upload"
}

export const CskhSidebarRoute = [
   {
      id: 1,
      path: ROUTE_NAME_CSKH.NHAPTHONGTIN,
      title: "Thông tin",
      status: false
   },
   {
      id: 2,
      path: ROUTE_NAME_CSKH.BAOCAO,
      title: "Báo cáo",
      status: false
   }
   ,
   {
      id: 3,
      path: ROUTE_NAME_CSKH.GUIMAIL,
      title: "Gửi mail",
      status: false
   }
   ,
   {
      id: 4,
      path: ROUTE_NAME_CSKH.UPLOAD,
      title: "Upload",
      status: false
   }
]
export const ROUTE_NAME_RUTDAO = {
   // HOME: "", 
   // lưu ý đường dẫn đến trang home của employer phải để rỗng, vì bên trong còn có các Link dẫn đến các component khác, 
   // nên nếu ở đây mình để là "home" thì các link đến component kia nó sẽ có chữ /home/ ở trước đường dẫn, dẫn đến sai, 
   // vì các Link nằm bên trong component EmployerIntro nên các Link đó theo nguyên tắc là con của coponent EmployerIntro
   KHACHHANG: "khachhang",
   GIAODICH: "giaodich",
   THETINDUNG: "thetindung",
   QUY: "quy",
   THUCHI: "thuchi",
   SAOKE: "saoke",
   NHANVIEN: "nhanvien"
}
export const RutdaoSubmenuRoute = [
   {
      id: 1,
      path: ROUTE_NAME_RUTDAO.KHACHHANG,
      title: "Khách hàng",
      status: false
   }
   ,
   {
      id: 2,
      path: ROUTE_NAME_RUTDAO.GIAODICH,
      title: "Giao dịch",
      status: false
   }
   ,
   {
      id: 3,
      path: ROUTE_NAME_RUTDAO.THETINDUNG,
      title: "Thẻ tín dụng",
      status: false
   }
   ,
   {
      id: 4,
      path: ROUTE_NAME_RUTDAO.QUY,
      title: "Quỹ",
      status: false
   }
   ,
   {
      id: 5,
      path: ROUTE_NAME_RUTDAO.THUCHI,
      title: "Thu Chi",
      status: false
   }
   ,
   {
      id: 6,
      path: ROUTE_NAME_RUTDAO.SAOKE,
      title: "Sao kê",
      status: false
   }
   ,
   {
      id: 8,
      path: ROUTE_NAME_RUTDAO.NHANVIEN,
      title: "Nhân viên",
      status: false
   }
]
export const ROUTE_NAME_KETOAN = {
   // HOME: "", 
   // lưu ý đường dẫn đến trang home của employer phải để rỗng, vì bên trong còn có các Link dẫn đến các component khác, 
   // nên nếu ở đây mình để là "home" thì các link đến component kia nó sẽ có chữ /home/ ở trước đường dẫn, dẫn đến sai, 
   // vì các Link nằm bên trong component EmployerIntro nên các Link đó theo nguyên tắc là con của coponent EmployerIntro
   TONGHOP: "tonghop",
   QUY: "quy",
   THUCHI: "thuchi",
   SAOKE: "saoke",
}
export const KetoanSubmenuRoute = [
   {
      id: 1,
      path: ROUTE_NAME_KETOAN.TONGHOP,
      title: "Tổng hợp",
      status: false
   }
   ,
   {
      id: 2,
      path: ROUTE_NAME_KETOAN.QUY,
      title: "Quỹ",
      status: false
   }
   ,
   {
      id: 3,
      path: ROUTE_NAME_KETOAN.THUCHI,
      title: "Thu Chi",
      status: false
   }
   ,
   {
      id: 4,
      path: ROUTE_NAME_KETOAN.SAOKE,
      title: "Sao kê",
      status: false
   }
   ,
]
export const ROUTE_NAME_QUANLY = {
   // HOME: "", 
   // lưu ý đường dẫn đến trang home của employer phải để rỗng, vì bên trong còn có các Link dẫn đến các component khác, 
   // nên nếu ở đây mình để là "home" thì các link đến component kia nó sẽ có chữ /home/ ở trước đường dẫn, dẫn đến sai, 
   // vì các Link nằm bên trong component EmployerIntro nên các Link đó theo nguyên tắc là con của coponent EmployerIntro
   CAPNHAT: "capnhat",
   NGUOIDUNG: "nguoidung",
   BAOCAO: "baocao",
   CAIDAT: "caidat",
}
export const QuanlySubMenuRoute = [
   {
      id: 1,
      path: ROUTE_NAME_QUANLY.CAPNHAT,
      title: "Cập nhật",
      status: false
   }
   ,
   {
      id: 2,
      path: ROUTE_NAME_QUANLY.NGUOIDUNG,
      title: "Người dùng",
      status: false
   }
   ,
   {
      id: 3,
      path: ROUTE_NAME_QUANLY.BAOCAO,
      title: "Báo cáo",
      status: false
   }
   ,
   {
      id: 4,
      path: ROUTE_NAME_QUANLY.CAIDAT,
      title: "Cài đặt",
      status: false
   }
   ,
]
export const ROUTE_NAME_AGENT = {
   // HOME: "", 
   // lưu ý đường dẫn đến trang home của employer phải để rỗng, vì bên trong còn có các Link dẫn đến các component khác, 
   // nên nếu ở đây mình để là "home" thì các link đến component kia nó sẽ có chữ /home/ ở trước đường dẫn, dẫn đến sai, 
   // vì các Link nằm bên trong component EmployerIntro nên các Link đó theo nguyên tắc là con của coponent EmployerIntro
   AGENT: "Agent",
   NGUOIDUNG: "nguoidung",
   BAOCAO: "baocao",
   CAIDAT: "caidat",
}
export const AgentSubMenuRoute = [
   {
      id: 1,
      path: ROUTE_NAME_AGENT.AGENT,
      title: "Agent",
      status: false
   }
   ,
   {
      id: 2,
      path: ROUTE_NAME_AGENT.NGUOIDUNG,
      title: "Người dùng",
      status: false
   }
   ,
   {
      id: 3,
      path: ROUTE_NAME_AGENT.BAOCAO,
      title: "Báo cáo",
      status: false
   }
   ,
   {
      id: 4,
      path: ROUTE_NAME_AGENT.CAIDAT,
      title: "Cài đặt",
      status: false
   }
   ,
]
export const ROUTE_NAME_BGD = {
   // HOME: "", 
   // lưu ý đường dẫn đến trang home của employer phải để rỗng, vì bên trong còn có các Link dẫn đến các component khác, 
   // nên nếu ở đây mình để là "home" thì các link đến component kia nó sẽ có chữ /home/ ở trước đường dẫn, dẫn đến sai, 
   // vì các Link nằm bên trong component EmployerIntro nên các Link đó theo nguyên tắc là con của coponent EmployerIntro
   TONGHOP: "tonghop",
   PHANTICH: "phantich",
   BAOCAO: "baocao",
}
export const BgdSubMenuRoute = [
   {
      id: 1,
      path: ROUTE_NAME_BGD.TONGHOP,
      title: "Tổng hợp",
      status: false
   }
   ,
   {
      id: 2,
      path: ROUTE_NAME_BGD.PHANTICH,
      title: "Phân tích",
      status: false
   }
   ,
   {
      id: 3,
      path: ROUTE_NAME_BGD.BAOCAO,
      title: "Báo cáo",
      status: false
   }
  
]

export const ROUTE_NAME_AGENT_OUTSIDE = {
   // HOME: "", 
   // lưu ý đường dẫn đến trang home của employer phải để rỗng, vì bên trong còn có các Link dẫn đến các component khác, 
   // nên nếu ở đây mình để là "home" thì các link đến component kia nó sẽ có chữ /home/ ở trước đường dẫn, dẫn đến sai, 
   // vì các Link nằm bên trong component EmployerIntro nên các Link đó theo nguyên tắc là con của coponent EmployerIntro
   KHACHHANG: "khachhang",
   HOIVIEN: "hoivien",
   PHANTICH: "phantich",
   THONGTIN: "thongtin",
   DIEM: "diem",
   LINK: "Link",
   CHINHSACH: "chinhsach",
}

export const AgentOutsideRoute = [
   {
      id: 1,
      path: ROUTE_NAME_AGENT_OUTSIDE.KHACHHANG,
      title: "Khách hàng",
      status: false
   }
   ,
   {
      id: 6,
      path: ROUTE_NAME_AGENT_OUTSIDE.LINK,
      title: "Link",
      status: false
   }
   ,
   {
      id: 2,
      path: ROUTE_NAME_AGENT_OUTSIDE.HOIVIEN,
      title: "Hội viên",
      status: false
   }
   ,
   {
      id: 3,
      path: ROUTE_NAME_AGENT_OUTSIDE.PHANTICH,
      title: "Phân tích",
      status: false
   }
   ,
   {
      id: 4,
      path: ROUTE_NAME_AGENT_OUTSIDE.THONGTIN,
      title: "Thông tin",
      status: false
   }
   ,
   {
      id: 5,
      path: ROUTE_NAME_AGENT_OUTSIDE.DIEM,
      title: "Điểm",
      status: false
   }

   ,
   {
      id: 7,
      path: ROUTE_NAME_AGENT_OUTSIDE.CHINHSACH,
      title: "Chính sách",
      status: false
   }

]