// recft_clsx_module_scss
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import styles from './general.module.scss'     //file scss cùng cấp
import { retrieveObjectFromLocalStorage } from '../../share/sharedFunction';
import { MenuRoute } from '../../share/route-option';
import { arrEmail_backOffice, arrRole_CSKH, arrRole_Finance, arrRole_KT } from '../../share/arrRole';



function Home() {
   const [stateArrMenuItems, setArrStateMenuItems] = useState(MenuRoute); //tạo state toggle và cho nội dung của nó ban đầu là mảng AdminMenuRoute
   
   // kiểm tra đã sign in chưa ------------------------------------------start
   const [stateBool_isSignedIn, setStateBool_isSignedIn] = useState(false); // boolean   
   const [stateObj_signIn, setStateObj_signIn] = useState<Record<string, any>>({
      token: "",
      email: "",
      fullName: "",
      role: ""
   })
   
   const checkAlreadySignIn1 = () => { // kiểm tra xem user đã sign in step 1 chưa
      
      const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
      // console.log('userExist: ', userExist);
      if(userExist !== null){
         setStateBool_isSignedIn(true);
         setStateObj_signIn(userExist);
      }
      else {
         setStateBool_isSignedIn(false);
      }
   
   }

   useEffect(() => {
      checkAlreadySignIn1();
   },[]);
   // kiểm tra đã sign in chưa ------------------------------------------end

   const navigate = useNavigate(); //hook dùng để chuyển trang web

   const selectDepartment = (title: string) => {
      switch (title) {
         case 'HR':
            // if(arrRolesHR.includes(stateObj_signIn.role)){
            //    navigate("/hr", { replace: true }); 
            // }
            // else {
            //    alert(`Chỉ có BGĐ và nhân viên phòng ${title} mới được dùng tính năng này`);
            // }
            alert("Trang này đang được bảo trì nên hiện tại chưa dùng được. Xin cảm ơn.");
            break;
         case 'CSKH':
            if(arrRole_CSKH.includes(stateObj_signIn.role)){
               navigate("/cskh", { replace: true }); 
            }
            else {
               alert(`Chỉ có BGĐ và nhân viên phòng ${title} mới được dùng tính năng này`);
            }
            break;
         case 'FINANCE':
            if(arrRole_Finance.includes(stateObj_signIn.role) || arrEmail_backOffice.includes(stateObj_signIn.email)){
               navigate("/finance", { replace: true }); 
            }
            else {
               alert(`Chỉ có BGĐ và nhân viên phòng ${title} mới được dùng tính năng này`);
            }
            break;
         case 'KẾ TOÁN':
            if(arrRole_KT.includes(stateObj_signIn.role)){
               navigate("/ketoan", { replace: true }); 
            }
            else {
               alert(`Chỉ có BGĐ và nhân viên phòng ${title} mới được dùng tính năng này`);
            }
            break;
         case 'PHẢN ÁNH':
            alert("Trang này đang được xây dựng nên hiện tại chưa dùng được. Xin cảm ơn.");
            break;
         case 'QUẢN LÝ':
            navigate("/quanly", { replace: true }); 
            break;
         default:
            break;
      }
   }
   const backToSignIn = () => {
   
      navigate("/signin", { replace: true }); 
      
   
   }

   if (stateBool_isSignedIn === false) {
      // User is not signed in, do not mount the component
      return (
         <div className={clsx(styles.component_Home)}>
            <h1>Phần này cần phải đăng nhập mới được phép xem !</h1>
            <h3>Bấm vào đây để quay lại trang sign in<span className={clsx(styles.btnSignIn)} onClick={backToSignIn}>Back to Sign in</span></h3>
         </div>
      )
   }
   else 
   {

      return (
         <>
            <div className={clsx(styles.component_Home)}>
               <img src="./assets/picture/wall2.jpg" className="background" alt="background" />
               
               <div className={clsx(styles.container)}>
                  
                  <h1>HỆ THỐNG QUẢN LÝ PHÒNG BAN</h1>
                  <div className={clsx(styles.departments)}>
                     {
                        stateArrMenuItems.slice(1).map((obj, index) => {
                           return ( 
                              <p className={clsx(styles.box)} key={obj.id} onClick={()=>selectDepartment(obj.title)}>{obj.title}</p>
                           )
                        })
                     }
                  </div>
               </div>
            </div>
         
         </>
      )
   }
}
export default Home


